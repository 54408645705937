// Bulma
// require("@vizuaalog/bulmajs");
import Bulma from "@vizuaalog/bulmajs";

// Disabled automatic loading of BulmaJS on window load
// we will do this programatically in a way that supports
// Turbolink
window.bulmaOptions = {
  autoParseDocument: false
}

// Attach Bulma plugins to all elements on page
document.addEventListener("turbolinks:load", function() {
  Bulma.parseDocument();
})

// Reset Bulma states before Turbolinks caches the page when we navigate away
document.addEventListener("turbolinks:before-cache", function() {

  // Close all dropdown
  const dropdowns = document.querySelectorAll('.dropdown');
  dropdowns.forEach(function(dropdown) {
    dropdown.classList.remove('is-active')
  })
})