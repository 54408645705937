// When page loads, register a changed event handler that displays warning messages
var handleChanged = function(e) {

  var selected = $('select',this).first().val()
  var target = $(this).data('warnTarget')

  if ($(this).data('warnOn') === selected) {
    $(this).addClass('is-danger')
    $(target).removeClass('is-hidden')
  } else {
    $(this).removeClass('is-danger')
    $(target).addClass('is-hidden')
  }
}

document.addEventListener("turbolinks:load", function() {
  $('.select').on('change', handleChanged)
})

// When we navigate away from a page, reset the warning indicators and message
var reset = function(e) {
  var target = $(this).data('warnTarget')
  $(target).addClass('is-hidden')
  $(this).removeClass('is-danger')
}

document.addEventListener("turbolinks:before-cache", function() {
  $('.select').each(reset)
  $('.select').off('change', handleChanged)
})