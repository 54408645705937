// Attach Bulma plugins to all elements on page
document.addEventListener("turbolinks:load", function() {
  // Close all dropdown
  const reveal_buttons = document.querySelectorAll('.password-reveal')
  reveal_buttons.forEach(function(btn) {
    btn.addEventListener('click', function (event) {
      target = document.querySelector(btn.dataset.revealTarget)
      if (target.type === "password") {
        target.type = "text";
        event.target.querySelector('i').classList.toggle('fa-eye-slash');
      } else {
        target.type = "password";
        event.target.querySelector('i').classList.toggle('fa-eye-slash');
      }
    })
  })
})
